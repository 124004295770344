<template>
  <div>
    <div style="text-align:right;">
      <el-button size="medium" type="primary" @click="showUploadDialod">上传文件</el-button>
    </div>


    <el-table
      class="list-table"
      :data="fileArr"
      tooltip-effect="dark"
      style="width: 100%;margin-top:20px;"
      :stripe="true"
      :border="false"
    >
      <el-table-column label="文件名称" prop="Name"></el-table-column>
      <el-table-column label="上传时间" prop="CreateTime">
        <template slot-scope="scope">
          {{scope.row.CreateTime|datetime}}
        </template>
      </el-table-column>
      <el-table-column label="地址" prop="FilePath"></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button type="success" icon="el-icon-edit" size="small" @click="onEdit(scope.row, scope.index)"
              >修改</el-button
            >
          <el-button
            type="warning"
            icon="el-icon-error"
            size="small"
            style="margin-left: 10px"
            @click="onRemove(scope.row, scope.index)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <el-dialog
      title="上传文件"
      :visible.sync="dialogFormVisible"
      width="500px"
      custom-class="geology-dialog"
      :modal-append-to-body="true"
      :destroy-on-close="true"
      :before-close="onDialogBeforeClose"
    >
      <el-form
        ref="form"
        class="geology-form"
        :rules="formRules"
        :model="fileForm"
        label-width="100px"
        style="padding-top:30px;"
      >
        <el-form-item label="文件名称" prop="Name">
          <el-col :span="20">
            <el-input v-model="fileForm.Name" size="small"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="上传文件" prop="FilePath">
          <el-col :span="20">
            <single-file
            ref="singleFile"
              text="选择文件"
              :auto-upload="false"
              :show-file-list="true"
              :file-list="fileList"
              list-type="text"
              @success="uploadSuccess"
              @change="uploadChange"
            ></single-file>
          </el-col>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="success" icon="el-icon-edit" size="small" @click="onUploadSubmit">确 定</el-button>
        <el-button
          size="small"
          style="margin-left: 10px"
          plain
          type="primary"
          @click="onDialogBeforeClose"
          >取 消</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { SingleFile } from "@/components/upload";
import { addDangerFile,delDangerFile,editDangerFile } from "@/api/base";
export default {
  data() {
    return {
      fileArr: [],
      dialogFormVisible: false,
      fileList:[],
      fileForm: {
        Name:'',
        FilePath:'',
      },
      formRules: {
        Name: [
          { required: true, trigger: "blur", message: "请输入文件名称" },
        ],
        FilePath: [
          { required: true, trigger: "blur", message: "请选择需要上传的文件" },
        ],
      },
      dialogFormType:'add',
      activeItem:'',
    };
  },
  props: {
    title: {
      type: String,
      default: "文件上传",
    },
    files: {
      type: Array,
      default: function () {
        return [];
      },
    },
    detailid: {
      type: [String,Number],
      default: '',
    },
    formtype: {
      type: String,
      default: 'add',
    },
  },
  components: {
    SingleFile,
  },
  methods: {
    onDialogBeforeClose(){
      this.fileList = []
      this.dialogFormVisible = false;
    },
    showUploadDialod() {
      this.fileForm = {
        Name:'',
        FilePath:''
      };
      this.fileList = []
      this.dialogFormType = 'add';
      this.dialogFormVisible = true;
    },
    uploadChange(file){
      if(!this.fileForm.Name || this.fileForm.Name==''){
        this.fileForm.Name = file.name;
        this.fileForm.FilePath = file.name;
      }
    },
    uploadSuccess(file) {
      var item = {
        RelationId: this.detailid,
        Name:this.fileForm.Name,
        FilePath:file.path,
        CreateTime:this.$formatDate(new Date(),'yyyy-MM-dd hh:mm:ss')
      };
      if(this.formtype=='edit'){
        if(this.dialogFormType != 'edit'){
          addDangerFile(item).then(res=>{
            if(res.code==0){
              this.$message.success("文件添加成功!");
              this.fileArr.push(item);
              this.$emit("update:images", this.fileArr);
              this.onDialogBeforeClose();
            }else{
              this.$message.error("文件添加失败!" + res.msg);
            }
          })
        }else{
          this.fileForm.FilePath = file.path;
          this.activeItem.Name = this.fileForm.Name;
          this.activeItem.FilePath = this.fileForm.FilePath;
          editDangerFile(this.fileForm).then(res=>{
            if(res.code==0){
              this.$message.success("文件修改成功!");
              this.$emit("update:images", this.fileArr);
              this.onDialogBeforeClose();
            }else{
              this.$message.error("文件修改失败!" + res.msg);
            }
          })
        }
      }else{
        this.fileArr.push(item);
        this.$emit("update:files", this.fileArr);
        this.dialogFormVisible = false;
      }
    },
    onUploadSubmit(){
      this.$refs["form"].validate((valid) => {
        if(valid){
          this.$refs.singleFile.submit();
        }
      })
    },
    onEdit(item){
      this.fileForm = JSON.parse(JSON.stringify(item));
      this.activeItem = item;
      this.fileList = [{
        name:item.Name,
        url:item.FilePath
      }]
      this.dialogFormType = 'edit';
      this.dialogFormVisible = true;
    },
    onRemove(item, index) {
      this.$emit("update:files", this.fileArr);
      if(this.formtype=='edit'){
        delDangerFile(item.Id).then(res=>{
          if(res.code==0){
            this.$message.success("文件删除成功!");
            this.fileArr.splice(index, 1);
          }else{
            this.$message.error("文件删除失败!" + res.msg);
          }
        })
      }
    },
  },
  created() {
  },
  watch:{
    files:function(){
      this.fileArr = this.files;
    }
  }
};
</script>